.photo-detail {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgb(255, 255, 255);
  display: flex;
  cursor: default;
  z-index: 1;
  // justify-content: center;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    justify-content: flex-start;
    height: auto;
  }

  .blur-img-wrp {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    // z-index: -1;
    overflow: hidden;
  }
  .blur-img {
    // position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transform: scale(1.2);
    // scale: 1.1;
    filter: blur(2rem);
    // z-index: -1;
  }

  .photo-detail-btn {
    position: absolute;
    margin: 2rem;
    height: 3rem;
    width: 3rem;
    font-size: 2rem;
    border: none;
    background: none;
    color: rgb(36, 36, 36);
    background: rgba(240, 240, 240, 0.5);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s;
    cursor: pointer;
    @media screen and (max-width: 800px) {
      margin: 1rem;
    }
    &:hover {
      background: rgba(240, 240, 240, 0.9);
      color: #87acff;
    }
    &.close {
      top: 0;
      right: 0;  
    }
    &.expand {
      bottom: 0;
      right: 0;  
    }
  }

  .photo-detail-left,
  .photo-detail-right {
    position: relative;
  }

  .photo-detail-left {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    &.full .photo-detail-img-wrp {
      height: 100%;
      width: 100%;
      max-height: 100%;
      max-width: 100%;
      padding: 0;
    }
  }

  .photo-detail-img-wrp {
    position: relative;
    height: 800px;
    width: 800px;
    max-height: 90%;
    max-width: 90%;
    // z-index: 1;
    padding: 2rem;
    @media screen and (max-width: 800px) {
      max-height: 20rem;
      width: 100%;
      // height: 100%;
      display: flex;
      justify-content: center;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
    video {
      height: 100%;
      width: 100%;
      object-fit: contain;      
    }

  }

  .photo-detail-infos-wrp {
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    padding: 4rem 4rem;
    align-items: center;
    height: 100%;
    overflow: auto;
    @media screen and (max-width: 800px) {
      padding: 2rem;
      height: auto;
      justify-content: center;
    }
  }
  .photo-detail-info-unit {
    display: flex;
    // justify-content: center;
    align-items: center;
    margin: 0.5rem 0;
    padding: 0.5rem;
    border: 1px solid #dddddd;
    border-radius: 1rem;
    width: 20rem;
    @media screen and (max-width: 800px) {
      width: 90%;
    }
    i {
      margin: 0 0.5rem;
      color: #87acff;
    }
    p {
      margin: 0 0.5rem;
      line-height: 1.5;
    }
  }
}
