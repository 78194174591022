
.guestbook-body {
  position: relative;
  height: 70%;
  &::before {
    z-index: 1;
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 2.5rem;
    margin: 0 auto;
    background: linear-gradient(rgba(73, 80, 95, 1),rgba(73, 80, 95, 0));
  }
  &::after {
    z-index: 1;
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2.5rem;
    margin: 0 auto;
    background: linear-gradient(rgba(73, 80, 95, 0),rgba(73, 80, 95, 1));
  }
  @media screen and (max-width: 800px) {
    height: 60%;
    padding: 1rem 0;
  }

}

.guestbook-list {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
  padding: 2rem 5rem;
  // margin: 1.5rem 5rem;
  &::-webkit-scrollbar {
    display: none;
  }
  @media screen and (max-width: 800px) {
    padding: 2rem 1rem;
    // margin: 1rem 1rem;
  }
}

.guestbook-list-unit {
  margin: 0.5rem 0;
  padding: 1rem 2rem;
  border-radius: 1.5rem;
  background: #8dadbf;
  position: relative;
  .guestbook-list-top {
    position: absolute;
    top: 1rem;
    font-size: 0.8rem;
    color: #DDDDDD;
    &.left {
      left: 2rem;
    }
    &.right {
      right: 2rem;
    }
  }
  .guestbook-list-text {
    margin-top: 1.3rem;
    p {
      word-break: break-all;
      line-height: 1.3;
    }
  }
}

.guestbook-write {
  // width: 50%;
  // margin: 2rem;
  position: relative;
  width: 100%;
  padding: 0 5rem;
  height: 4rem;
  display: flex;
  @media screen and (max-width: 800px) {
    padding: 0rem 1rem;
  }
  textarea {
    // height: 2rem;
    height: 100%;
    border: none;
    width: calc(100% - 5rem);
    resize: none;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    outline: none;
    font-size: 1rem;
  }
  button {
    height: 100%;
    width: 5rem;
    background-color: #8dadbf;
    border: none;
    color: #FFFFFF;
    font-size: 1rem;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    outline: none;
  }
}

.nickname-wrp {
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  p {
    margin: 2rem;
  }
  input {
    width: 17rem;
    height: 2.5rem;
  }
  button {
    margin: 1rem;
    width: 5rem;
    height: 2.5rem;
    background-color: #8dadbf;
    border: none;
    color: #FFFFFF;
    font-size: 1.1rem;
    border-radius: 0.5rem;
  }
}