.welcome-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  // @media screen and (max-width: 800px) {
  //   height: auto;
  //   min-height: 100%;
  // }

  .poster-img-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000927;
    transition: all 1s;
    &.hidden {
      visibility: hidden;
      background-color: #ffffff;
      opacity: 1;
    }
  }
  .poster-img {
    margin: 0 auto;
    max-height: 80%;
    max-width: 80%;
    object-fit: contain;
    cursor: pointer;
  }
  .background-img {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
  .background-cover {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media screen and (max-width: 800px) {
      justify-content: flex-start;
      height: auto;
      min-height: 100%;
    }
    // background-color: rgba(50, 50, 50, 0.3);
  }
  .intro {
    transition: all 1s;
    &.hidden {
      visibility: hidden;
      opacity: 0;
      // visibility: hidden;
      // opacity: 1;
    }
  }
  .intro-msg {
    position: absolute;
    top: 5rem;
    // left: 5rem;
    width: 100%;
    padding: 1rem 15rem;
    color: white;
    font-size: 1.2rem;
    line-height: 1.5;
    .msg-rigt {
      width: 100%;
      text-align: right;
    }
    @media screen and (max-width: 800px) {
      position: relative;
      top: 0;
      left: 0;
      font-size: 0.9rem;
      width: 100%;
      padding: 1.5rem;
    }
  }
  .intro-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20rem;
    // min-height: 15rem;
    // min-height: 50%;
    position: relative;
    background-color: rgba(73, 80, 95, 0.82);
    color: white;
    border-radius: 1rem;
    padding: 1rem;
    z-index: 1;
    @media screen and (max-width: 800px) {
      padding: 0.5rem;
    }
    // box-shadow: 0px 0px 10px 10px rgba(255, 255, 255, 0.5);
    p {
      display: block;
      text-align: left;
      margin: 1rem;
      font-size: 1.1rem;
      line-height: 1.5;
      @media screen and (max-width: 800px) {
        font-size: 1rem;
      }
    }

    .intro-btn-enter {
      margin: 1rem auto;
      width: 5rem;
      height: 2.5rem;
      background-color: #8dadbf;
      border: none;
      color: #ffffff;
      font-size: 1.1rem;
      border-radius: 0.5rem;
      // margin: 2rem auto;
      // height: 3rem;
      // width: 6rem;
      // font-size: 1.2rem;
      // border-radius: 1rem;
    }
    .btn-google {
      background: transparent;
      // background-color: #FFFFFF;
      // border: 1px solid #CCCCCC;
      // padding: 1rem;
      border: none;
      // border-radius: 0.5rem;
    }
    // h4
  }
  .intro-footer {
    margin-top: auto;
    text-decoration: underline;
    p {
      line-height: 1.3;
      cursor: pointer;
    }
  }
}

.privacy-wrp {
  position: relative;
  padding-top: 1rem;
}

.privacy-btn-close {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0rem;
  height: 2rem;
  width: 2rem;
  font-size: 1.5rem;
  border: none;
  background: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #8dadbf;
  transition: all 0.2s;
  &:hover {
    color: #ffffff;
    // border: 1px solid #FFFFFF;
    // background: rgba(240, 240, 240, 0.9);
  }
  @media screen and (max-width: 800px) {
    // margin: 1.5rem;
  }
}
