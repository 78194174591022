.popup-wrp {
  position: absolute;
  height: 100%;
  width: 100%;
  // background: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
}

.popup {
  position: relative;
  height: 80%;
  width: 50%;
  background-color: rgb(73, 80, 95);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  color: #ffffff;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    justify-content: flex-start;
    height: 90%;
    width: 90%;
  }

  .popup-btn-close {
    position: absolute;
    top: 0;
    right: 0;
    margin: 2rem;
    height: 3rem;
    width: 3rem;
    font-size: 2.5rem;
    border: none;
    background: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #8dadbf;
    transition: all 0.2s;
    &:hover {
      color: #ffffff;
      // border: 1px solid #FFFFFF;
      // background: rgba(240, 240, 240, 0.9);
    }
    @media screen and (max-width: 800px) {
      margin: 1.5rem;
    }
  }

  h3 {
    margin-top: 3rem;
    margin-bottom: 1rem;
    margin-left: 2.5rem;
    margin-right: 2.5rem;
    padding-bottom: 2rem;
    font-size: 3rem;
    text-align: center;
    border-bottom: 1px solid #909090;
    @media screen and (max-width: 800px) {
      font-size: 2rem;
    }
  }
}

.tooltip-body {
  padding-top: 1rem;
  padding-bottom: 5rem;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  i {
    font-size: 2rem;
  }
  p {
    text-align: center;
    margin: 2rem;
    line-height: 1.5;
  }
  @media screen and (max-width: 800px) {
    p {
        margin: 1rem;
      }
    
  }
}
